import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const CardNews = ({ post }) => {
  const cardButtonLabel = "Lire l'article";

  const { image, title, category, createdAt, date, slug } = post;

  return (
    <article className="card--parcours">
      <Link to={`/actualites/${slug}`} className="card--parcours__img">
        <GatsbyImage image={getImage(image)} alt={image.title || title} />
        <div className="card--parcours__duration text-1rem text--uppercase">
          {category}
        </div>
      </Link>
      <div className="card--parcours--content">
        <div className="padding-card">
          <div className="text-primary fw-700 mt-0-75 mb-0-5">
            {date || createdAt}
          </div>
          <h3 className="h4 mb-0">{title}</h3>
        </div>
      </div>
      <Link
        to={`/actualites/${slug}`}
        className="button small centered black mb-1-5"
      >
        {cardButtonLabel}
      </Link>
    </article>
  );
};

export default CardNews;
