import React from "react";
import ButtonFilter from "../atoms/ButtonFilter";

const Filters = ({ categories, active, handleClick }) => {
  return (
    <div className="button-group mt-3 mb-minus show-for-medium">
      <ButtonFilter cat="Tous" handleClick={handleClick} active={active} />

      {categories.map((cat) => (
        <ButtonFilter
          key={cat}
          cat={cat}
          handleClick={handleClick}
          active={active}
        />
      ))}
    </div>
  );
};

export default Filters;
