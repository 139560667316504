import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";
import Head from "../../components/head";
import Layout from "../../components/layout";
import ContactLink from "../../components/sections/ContactLink";
import FeaturedNews from "../../components/sections/FeaturedNews";
import Filters from "../../components/sections/Filters";
import HeroStandard from "../../components/sections/HeroStandard";

const Actualites = () => {
  const data = useStaticQuery(graphql`
    query {
      posts: allContentfulPost {
        nodes {
          title
          category
          date
          slug
          createdAt(formatString: "DD MM YYYY")
          image {
            gatsbyImageData(
              layout: FIXED
              placeholder: BLURRED
              width: 370
              height: 220
            )
          }
        }
      }
      categories: allContentfulPost {
        nodes {
          category
        }
      }
    }
  `);

  const posts = data.posts.nodes;
  const categoriesList = [...data.categories.nodes];
  const formated = categoriesList.map((cat) => cat.category);
  const formatedCategories = [...new Set(formated)];

  const [currentCategory, setCurrentCategory] = useState("Tous");
  const [allPosts] = useState(posts);
  const [selectedPosts, setSelectedPosts] = useState(posts);

  useEffect(() => {
    if (currentCategory === "Tous") {
      setSelectedPosts(allPosts);
    } else {
      const newSelection = [...allPosts].filter(
        (post) => post.category === currentCategory
      );
      setSelectedPosts(newSelection);
    }
  }, [currentCategory, allPosts]);

  const handleCategoryChange = function (e) {
    setCurrentCategory(e.target.value);
  };

  return (
    <Layout>
      <Head
        title="Toutes les actualités de Strasbourg Running Tours"
        description="Suivez les actualités, articles de Strasbourg Running Tours et tenez-vous au courant des événements running en Alsace et dans le Grand-Est"
      />
      <HeroStandard title="Actualités et événements Running en Alsace" />
      <Filters
        categories={formatedCategories}
        active={currentCategory}
        handleClick={handleCategoryChange}
      />
      {posts.length > 0 ? (
        <FeaturedNews featuredNews={selectedPosts} />
      ) : (
        <section className="mod">
          <h2 className="centered mb-3">
            Vous trouverez ici prochainement des actualités sur Strasbourg
            Running Tours et les événements running alsaciens
          </h2>
        </section>
      )}

      <ContactLink />
    </Layout>
  );
};

export default Actualites;
