import { Link } from "gatsby";
import React from "react";
import CardNews from "../atoms/CardNews";

const FeaturedNews = ({ newsTitle, newsButton, featuredNews }) => {
  if (featuredNews.length === 0) return;

  return (
    <section className="mod bg-white">
      {newsTitle && (
        <h2 className="mb-2 letter-spacing underlined">{newsTitle}</h2>
      )}
      <div className="mod--grid mt-2 mb-2">
        {featuredNews.map((post, key) => (
          <CardNews key={key} post={post} />
        ))}
      </div>
      {newsButton && (
        <Link className="button black centered mb-0" to="/actualites">
          {newsButton}
        </Link>
      )}
    </section>
  );
};

export default FeaturedNews;
