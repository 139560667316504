import React from "react";

const ButtonFilter = ({ cat, active, handleClick }) => {
  return (
    <button
      className={`button small ${active === cat ? "primary" : "secondary"}`}
      onClick={handleClick}
      value={cat}
    >
      {cat}
    </button>
  );
};

export default ButtonFilter;
